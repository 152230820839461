@import "_variables.scss";
@import "_mixins.scss";
@import "_sidebar.scss";
@import "_side-catalog.scss";
@import "_search.scss";
@import "_highlight.scss";

// Global Components

body {
  @include sans-serif;
  font-size: 16px;
  // Hux mpdify to 16px (Mobile First), and increase to 20px while 768+ width
  color: $gray-dark;
  //-webkit-user-select:text; //对于 Blog 还是先不开启这句。
  overflow-x: hidden;

  text-rendering: auto;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

// -- Typography

p {
  margin: 30px 0;
  @media screen and (max-width: 768px) {
    & {
      margin: 25px 0;
    }
  }
}

h1,
h2,
h3,
h4,
h5,
h6 {
  @include sans-serif;
  line-height: 1.1;
  font-weight: bold;
}
h4 {
  font-size: 21px;
}
a {
  color: $gray-dark;
  &:hover,
  &:focus {
    color: $brand-primary;
  }
}

a img {
  &:hover,
  &:focus {
    cursor: zoom-in;
  }
}

article {
  overflow: hidden;
}
blockquote {
  color: gray;
  font-style: italic;
  font-size: 0.95em;
  margin: 20px 0 20px;
  p {
    margin: 0;
  }
}

// Utils Style Class can be used in Markdown.
small.img-hint {
  display: block;
  margin-top: -20px;
  text-align: center;
}
br + small.img-hint {
  margin-top: -40px;
}
img.shadow {
  box-shadow: rgba(0, 0, 0, 0.258824) 0px 2px 5px 0px;
}
// Utils Style End

// Select
select {
  -webkit-appearance: none;
  margin-top: 15px;
  color: #337ab7;
  border-color: #337ab7;
  padding: 0em 0.4em;
  background: white;
  &.sel-lang {
    min-height: 28px;
    font-size: 14px;
  }
}

// override table style in bootstrap
table {
  margin-top: 10px;
}
table.table > tbody,
table.table > thead {
  th,
  td {
    border: 1px solid #eee !important;
  }
}
@media screen and (max-width: 767px) {
  .table-responsive {
    border: 0;
  }
}

hr.small {
  max-width: 100px;
  margin: 15px auto;
  border-width: 4px;
  border-color: white;
}
// add by Hux
pre,
.table-responsive {
  // sometimes you should use vendor-feature.
  -webkit-overflow-scrolling: touch;
}
pre code {
  display: block;
  width: auto;
  white-space: pre; // save it but no-wrap;
  word-wrap: normal; // no-wrap
}
code,
kbd,
pre,
samp {
  @include monospace;
}

// In the list of posts
.postlist-container {
  margin-bottom: 15px;
}

// In the post.
.post-container {
  a {
    // display: inline-block;
    // safari has bugs on word-break on inline elements.
    color: #337ab7;
    // different to $brand-primary
    &:hover,
    &:focus {
      color: $brand-primary;
    }
  }
  h1,
  h2,
  h3,
  h4,
  h5,
  h6 {
    margin: 50px 0 20px;
    line-height: 1.4;
    & + p {
      margin-top: 20px;
    }
  }
  h2::before {
    content: " ";
    display: block;
    border-bottom: 1px solid #ececec;
    margin-top: 44px;
    margin-bottom: 30px;
  }
  h4 {
    font-size: 22px;
    font-weight: 600;
    color: gray;
    & + p {
      margin-top: 10px;
    }
  }
  h5,
  h6 {
    font-size: 20px;
    font-weight: 600;
    color: gray;
    & + p {
      margin-top: 10px;
    }
  }
  // downscale the font a little bit in mobile
  @media screen and (max-width: 768px) {
    h1 {
      font-size: 30px;
    }
    h2 {
      font-size: 22px;
    }
    h3 {
      font-size: 20px;
    }
    h4 {
      font-size: 19px;
    }
    h5,
    h6 {
      font-size: 18px;
    }
  }
  ul,
  ol {
    margin-bottom: 40px;
    @media screen and (max-width: 768px) {
      & {
        padding-left: 30px;
      }
    }
    @media screen and (max-width: 500px) {
      & {
        padding-left: 20px;
      }
    }
  }
  ol ol,
  ol ul,
  ul ol,
  ul ul {
    margin-bottom: 5px;
  }
  li {
    p {
      margin: 0;
      margin-bottom: 5px;
    }
    h1,
    h2,
    h3,
    h4,
    h5,
    h6 {
      line-height: 2;
      margin-top: 20px;
    }
  }
  // V1.6 Hux display title by default.
  .pager li {
    width: 48%;
    &.next {
      float: right;
    }
    &.previous {
      float: left;
    }
    > a {
      width: 100%;
      > span {
        color: $brand-gray;
        font-weight: normal;
        letter-spacing: 0.5px;
      }
    }
  }
  .anchorjs-link {
    // I can not understand this but this made anchor always in the same line as title
    position: absolute;
    text-decoration: none !important;
  }

  .anchorjs-link {
    font-family: serif;
    font-style: normal;
    font-variant: normal;
    font-weight: normal;
    padding-left: 0.375em;
    font-size: 0.9em;
    margin-top: 0.1em;
    opacity: 0;
    -webkit-font-smoothing: antialiased;
    text-rendering: optimizeLegibility;
    /* Support for Firefox. */
    -moz-osx-font-smoothing: grayscale;
    /* Support for IE. */
    font-feature-settings: "liga";

    &:hover,
    &:focus {
      opacity: 1;
    }
    &:after {
      content: "¶";
    }
  }

  @media all and (min-width: 800px) {
    .anchorjs-link {
      position: absolute;
      left: -0.75em;
    }
  }

  figure {
    margin: .5rem;
    text-align: center;

    .image-caption:not(:empty) {
      min-width: 20%;
      max-width: 80%;
      display: inline-block;
      font-style: italic;
      padding: .5rem;
      margin: 0 auto;
      font-size: 13px;
      color: $brand-gray;
    }

    img {
      display: block;
      width: 100%;
      height: auto;
      margin: 0 auto;
      overflow: hidden;
    }
  }
}

// Navigation

// materialize, mobile only
@media only screen and (max-width: 767px) {
  /**
   * Layout
   * Since V1.6 we use absolute positioning to prevent to expand container-fluid
   * which would cover tags. A absolute positioning make a new layer.
   */
  .navbar-default .navbar-collapse {
    position: absolute;
    right: 0;
    border: none;
    background: white;
    box-shadow: 0px 5px 10px 2px rgba(0, 0, 0, 0.2);
    box-shadow: rgba(0, 0, 0, 0.117647) 0px 1px 6px,
      rgba(0, 0, 0, 0.239216) 0px 1px 4px;
    border-radius: 2px;
    width: 170px;
  }
  /**
   * Animation
   * HuxBlog-Navbar using genuine Material Design Animation
   */
  #huxblog_navbar {
    /**
     * Sharable code and 'out' function
     */
    // the container do width-transition
    opacity: 0;
    transform: scaleX(0);
    transform-origin: top right;
    transition: all 200ms cubic-bezier(0.47, 0, 0.4, 0.99) 0ms;
    -webkit-transform: scaleX(0);
    -webkit-transform-origin: top right;
    -webkit-transition: all 200ms cubic-bezier(0.47, 0, 0.4, 0.99) 0ms;
    a {
      font-size: 13px;
      line-height: 28px;
    }
    .navbar-collapse {
      // the navbar do height-transition
      height: 0px; // to solve container-mask-tags issue (default state).
      transform: scaleY(0);
      transform-origin: top right;
      transition: transform 400ms cubic-bezier(0.32, 1, 0.23, 1) 0ms;
      -webkit-transform: scaleY(0);
      -webkit-transform-origin: top right;
      -webkit-transition: -webkit-transform 400ms cubic-bezier(0.32, 1, 0.23, 1)
        0ms;
    }
    li {
      opacity: 0;
      transition: opacity 100ms cubic-bezier(0.23, 1, 0.32, 1) 0ms;
      -webkit-transition: opacity 100ms cubic-bezier(0.23, 1, 0.32, 1) 0ms;
    }
    /**
     *'In' Animation
     */
    &.in {
      transform: scaleX(1);
      -webkit-transform: scaleX(1);
      opacity: 1;
      transition: all 250ms cubic-bezier(0.23, 1, 0.32, 1) 0ms;
      -webkit-transition: all 250ms cubic-bezier(0.23, 1, 0.32, 1) 0ms;
      .navbar-collapse {
        transform: scaleY(1);
        -webkit-transform: scaleY(1);
        transition: transform 500ms cubic-bezier(0.23, 1, 0.32, 1);
        -webkit-transition: -webkit-transform 500ms
          cubic-bezier(0.23, 1, 0.32, 1);
      }
      li {
        opacity: 1;
        transition: opacity 450ms cubic-bezier(0.23, 1, 0.32, 1) 205ms;
        -webkit-transition: opacity 450ms cubic-bezier(0.23, 1, 0.32, 1) 205ms;
      }
    }
  }
}

.navbar-custom {
  // materialize
  background: none;
  border: none;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  z-index: 3;
  @include sans-serif;
  .navbar-brand {
    font-weight: 800;
    // materialize
    color: white;
    height: 56px;
    line-height: 25px;
    &:hover {
      color: rgba(255, 255, 255, 0.8);
    }
  }
  .nav {
    li {
      a {
        text-transform: uppercase;
        font-size: 12px;
        line-height: 20px;
        font-weight: 800;
        letter-spacing: 1px;
        // only highlight in mobile
        &:active {
          background: rgba(0, 0, 0, 0.12);
        }
      }
    }
  }
  @media only screen and (min-width: 768px) {
    body {
      font-size: 20px;
    }
    background: transparent;
    border-bottom: 1px solid transparent;
    .navbar-brand {
      color: white;
      padding: 20px;
      line-height: 20px;
      &:hover,
      &:focus {
        color: $white-faded;
      }
    }
    .nav {
      li {
        a {
          color: white;
          padding: 20px;
          &:hover,
          &:focus {
            color: $white-faded;
          }
          &:active {
            background: none;
          }
        }
      }
    }
  }
  &.invert {
    .navbar-toggle {
      &:active {
        background-color: rgba(0, 0, 0, 0.05);
      }
      .icon-bar {
        background-color: $gray-dark;
      }
    }
    .navbar-brand {
      color: $gray-dark;
      &:hover,
      &:focus {
        color: $brand-primary;
      }
    }
    .nav {
      li {
        a {
          color: $gray-dark;
          &:hover,
          &:focus {
            color: $brand-primary;
          }
        }
      }
    }
  }
  @media only screen and (min-width: 1170px) {
    -webkit-transition: background-color 0.3s;
    -moz-transition: background-color 0.3s;
    transition: background-color 0.3s;
    /* Force Hardware Acceleration in WebKit */
    -webkit-transform: translate3d(0, 0, 0);
    -moz-transform: translate3d(0, 0, 0);
    -ms-transform: translate3d(0, 0, 0);
    -o-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0);
    -webkit-backface-visibility: hidden;
    backface-visibility: hidden;
    &.is-fixed {
      /* when the user scrolls down, we hide the header right above the viewport */
      position: fixed;
      top: -61px;
      background-color: rgba(255, 255, 255, 0.9);
      border-bottom: 1px solid #f2f2f2;
      -webkit-transition: -webkit-transform 0.3s;
      -moz-transition: -moz-transform 0.3s;
      transition: transform 0.3s;
      .navbar-brand {
        color: $gray-dark;
        &:hover,
        &:focus {
          color: $brand-primary;
        }
      }
      .nav {
        li {
          a {
            color: $gray-dark;
            &:hover,
            &:focus {
              color: $brand-primary;
            }
          }
        }
      }
    }
    &.is-visible {
      /* if the user changes the scrolling direction, we show the header */
      -webkit-transform: translate3d(0, 100%, 0);
      -moz-transform: translate3d(0, 100%, 0);
      -ms-transform: translate3d(0, 100%, 0);
      -o-transform: translate3d(0, 100%, 0);
      transform: translate3d(0, 100%, 0);
    }
  }
}

// Header

.intro-header {
  &.style-text {
    background: none;
    .site-heading,
    .post-heading,
    .page-heading {
      padding: 85px 0 20px;
      color: $gray-dark;
      .subheading {
        margin-bottom: 15px;
      }
    }
    .tags {
      a,
      .tag {
        border-color: $gray-dark;
        color: $gray-dark;
        &:hover,
        &:active {
          background-color: rgba(0, 0, 0, 0.05);
        }
      }
    }
  }
  background: no-repeat center center;
  background-color: $brand-gray;
  background-attachment: scroll;
  @include background-cover;
  // NOTE: Background images are set within the HTML using inline CSS!
  margin-bottom: 0px; /* 0 on mobile, modify by Hux */
  @media only screen and (min-width: 768px) {
    margin-bottom: 20px; /* response on desktop */
  }
  .site-heading,
  .post-heading,
  .page-heading {
    padding: 85px 0 55px;
    color: white;
    @media only screen and (min-width: 768px) {
      padding: 150px 0;
    }
  }
  // masterialize
  .site-heading {
    padding: 95px 0 70px;
    @media only screen and (min-width: 768px) {
      padding: 150px 0;
    }
  }
  .site-heading,
  .page-heading {
    text-align: center;
    h1 {
      margin-top: 0;
      font-size: 50px;
    }
    .subheading {
      @include sans-serif;
      font-size: 18px;
      line-height: 1.1;
      display: block;
      font-weight: 300;
      margin: 10px 0 0;
    }
    @media only screen and (min-width: 768px) {
      h1 {
        font-size: 80px;
      }
    }
  }
  .post-heading {
    h1 {
      font-size: 30px;
      margin-bottom: 24px;
    }
    .subheading,
    .meta {
      line-height: 1.1;
      display: block;
    }
    .subheading {
      @include sans-serif;
      font-size: 17px;
      line-height: 1.4;
      font-weight: normal;
      margin: 10px 0 30px;
      margin-top: -5px;
    }
    .meta {
      @include serif;
      font-style: italic;
      font-weight: 300;
      font-size: 16px;
      a {
        color: white;
      }
    }
    @media only screen and (min-width: 768px) {
      h1 {
        font-size: 55px;
      }
      .subheading {
        font-size: 30px;
      }
      .meta {
        font-size: 20px;
      }
    }
  }
  .header-img-credit {
    position: absolute;
    bottom: 6px;
    right: 9px;
    color: white;
    opacity: 0.3;
    font-size: 10px;
    z-index: 1;
    a {
      color: white;
    }
    @media only screen and (min-width: 768px) {
      & {
        font-size: 12px;
        bottom: 10px;
        right: 15px;
      }
    }
  }
}

// Post Preview Pages (Home Page)

.post-preview {
  > a {
    color: $gray-dark;
    &:hover,
    &:focus {
      text-decoration: none;
      color: $brand-primary;
    }
    > .post-title {
      font-size: 21px;
      line-height: 1.3;
      margin-top: 30px;
      margin-bottom: 8px;
    }
    > .post-subtitle {
      font-size: 15px;
      line-height: 1.3;
      margin: 0;
      font-weight: 300;
      margin-bottom: 10px;
    }
  }
  > .post-meta {
    @include serif;
    color: $brand-gray;
    font-size: 16px;
    font-style: italic;
    margin-top: 0;
    > a {
      text-decoration: none;
      color: $gray-dark;
      &:hover,
      &:focus {
        color: $brand-primary;
        text-decoration: underline;
      }
    }
  }
  @media only screen and (min-width: 768px) {
    > a {
      > .post-title {
        font-size: 26px;
        line-height: 1.3;
        margin-bottom: 10px;
      }
      > .post-subtitle {
        font-size: 16px;
      }
    }
    .post-meta {
      font-size: 18px;
    }
  }
}

// Hux add home-page post-content-preview
.post-content-preview {
  font-size: 13px;
  font-style: italic;
  color: $brand-gray !important;

  p {
    margin: 0;
  }

  &:hover {
    color: $brand-primary;
  }
  @media only screen and (min-width: 768px) {
    font-size: 14px;
  }
}

// Sections

.section-heading {
  font-size: 36px;
  margin-top: 60px;
  font-weight: 700;
}

.caption {
  text-align: center;
  font-size: 14px;
  padding: 10px;
  font-style: italic;
  margin: 0;
  display: block;
  border-bottom-right-radius: 5px;
  border-bottom-left-radius: 5px;
}

footer {
  font-size: 20px;
  padding: 50px 0 65px;
  .list-inline {
    margin: 0;
    padding: 0;
  }
  .copyright {
    font-size: 14px;
    text-align: center;
    margin-bottom: 0;
    a {
      color: #337ab7;
      // different to $brand-primary
      &:hover,
      &:focus {
        color: $brand-primary;
      }
    }
  }
}
// Button Styles

.btn {
  @include sans-serif;
  text-transform: uppercase;
  font-size: 14px;
  font-weight: 800;
  letter-spacing: 1px;
  border-radius: 0;
  padding: 15px 25px;
}

.btn-lg {
  font-size: 16px;
  padding: 25px 35px;
}

.btn-default {
  &:hover,
  &:focus {
    background-color: $brand-primary;
    border: 1px solid $brand-primary;
    color: white;
  }
}

// Pager Styling

.pager {
  margin: 20px 0 0 !important;
  padding: 0px !important;

  li {
    > a,
    > span {
      @include sans-serif;
      text-transform: uppercase;
      font-size: 13px;
      font-weight: 800;
      letter-spacing: 1px;
      padding: 10px;
      background-color: white;
      border-radius: 0;
      @media only screen and (min-width: 768px) {
        font-size: 14px;
        padding: 15px 25px;
      }
    }

    > a {
      color: $gray-dark;
    }
    > a:hover,
    > a:focus {
      color: white;
      background-color: $brand-primary;
      border: 1px solid $brand-primary;

      // V1.6 display title
      > span {
        color: white;
      }
    }
  }

  .disabled {
    > a,
    > a:hover,
    > a:focus,
    > span {
      color: $brand-gray;
      background-color: $gray-dark;
      cursor: not-allowed;
    }
  }
}

// -- Highlight Color Customization

::-moz-selection {
  color: white;
  text-shadow: none;
  background: $brand-primary;
}

::selection {
  color: white;
  text-shadow: none;
  background: $brand-primary;
}

img::selection {
  color: white;
  background: transparent;
}

img::-moz-selection {
  color: white;
  background: transparent;
}

/* Hux add tags support */
.d-none {
  display: none !important;
}
.tags {
  margin-bottom: -5px;
  &.tags-sup {
    a,
    .tag {
      padding: 0 10px 0 12px;
    }
  }
  a,
  .tag {
    display: inline-block;
    border: 1px solid rgba(255, 255, 255, 0.8);
    border-radius: 999em;
    padding: 0 10px 0 10px;
    color: rgba(255, 255, 255, 1);
    line-height: 24px;
    font-size: 12px;
    text-decoration: none;
    margin: 0 1px;
    margin-bottom: 6px;
    cursor: pointer;
    > sup {
      margin-left: -2px;
      font-weight: 700;
    }
    &:hover,
    &:active {
      color: white;
      border-color: white;
      background-color: rgba(255, 255, 255, 0.4);
      text-decoration: none;
    }
    @media only screen and (min-width: 768px) {
      margin-right: 5px;
    }
  }
}

#tag-heading {
  padding: 70px 0 60px;
  @media only screen and (min-width: 768px) {
    padding: 55px 0;
  }
}
// tag_cloud
#tag_cloud {
  margin: 20px 0 15px 0;
  a,
  .tag {
    transition-property: all;
    transition-duration: 0.4s;
    transition-timing-function: ease;
    font-size: 14px;
    border: none;
    line-height: 28px;
    margin: 0 2px;
    margin-bottom: 8px;
    background: #f3f5f5;
    &:hover,
    &:active,
    &.focus {
      background-color: #0085a1 !important;
    }
    &.focus {
      box-shadow: rgba(0, 0, 0, 0.117647) 0 1px 6px,
        rgba(0, 0, 0, 0.239216) 0 1px 4px;
    }
    &.tag-button--all {
      font-weight: 700;
      color: #0085a1 !important;
      &:hover,
      &:active,
      &.focus {
        background-color: #e4e4e4 !important;
      }
    }
  }
  @media only screen and (min-width: 768px) {
    margin-bottom: 25px;
  }
}

.tag-comments {
  font-size: 12px;
  @media only screen and (min-width: 768px) {
    font-size: 14px;
  }
}

.t {
  //margin-top: 50px;
  &:first-child {
    margin-top: 0px;
  }
  hr:last-of-type {
    //display: none;
  }
}
.listing-seperator {
  color: #0085a1;
  font-size: 21px !important;
  &::before {
    margin-right: 5px;
  }
  @media only screen and (min-width: 768px) {
    font-size: 20px !important;
    line-height: 2 !important;
  }
}

// Customize Style for Posts in Mini-Post-List
.mini-post-list {
  margin: 20px 0 15px 0;
  .tag-text {
    font-weight: 200;
    @include sans-serif;
  }
  .post-preview {
    position: relative;
    > a {
      .post-title {
        font-size: 16px;
        font-weight: 500;
        margin-top: 20px;
      }
      .post-subtitle {
        font-size: 13px;
      }
    }
    > .post-meta {
      position: absolute;
      right: 5px;
      bottom: 0px;
      margin: 0px;
      font-size: 12px;
      line-height: 12px;
    }
    @media only screen and (min-width: 768px) {
      margin-left: 20px;
      > a {
        > .post-title {
          font-size: 18px;
          line-height: 1.3;
        }
        > .post-subtitle {
          font-size: 14px;
        }
      }
      .post-meta {
        font-size: 18px;
      }
    }
  }
}

/* Tags support End*/

/* Hux make all img responsible in post-container */
.post-container img {
  display: block;
  max-width: 100%;
  height: auto;
  margin: 1.5em auto 1.6em auto;
}

/* Hux Optimize UserExperience */
.navbar-default .navbar-toggle {
  &:focus,
  &:hover {
    background-color: inherit;
  }
  &:active {
    background-color: rgba(255, 255, 255, 0.25);
  }
}

/* Hux customize Style for navBar button */

.navbar-default .navbar-toggle {
  border-color: transparent;
  padding: 19px 16px;
  margin-top: 2px;
  margin-right: 2px;
  margin-bottom: 2px;
  border-radius: 50%;
  .icon-bar {
    width: 18px;
    border-radius: 0px;
    // materialize
    background-color: white;
  }
  .icon-bar + .icon-bar {
    margin-top: 3px;
  }
}

// Hux fullscreen mode in 404.html

.page-fullscreen .intro-header {
  position: fixed;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
}
.page-fullscreen #tag-heading {
  position: fixed;
  left: 0;
  top: 0;
  padding-bottom: 150px;
  width: 100%;
  height: 100%;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-box-pack: center;
  -webkit-box-align: center;

  display: -webkit-flex;
  -webkit-align-items: center;
  -webkit-justify-content: center;
  -webkit-flex-direction: column;

  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
}
.page-fullscreen footer {
  position: absolute;
  width: 100%;
  bottom: 0;
  padding-bottom: 20px;
  opacity: 0.6;
  color: #fff;
}
.page-fullscreen footer .copyright {
  color: #fff;
}
.page-fullscreen footer .copyright a {
  color: #fff;
}
.page-fullscreen footer .copyright a:hover {
  color: #ddd;
}

// MathJax Overflow
.MathJax_SVG_Display {
  overflow: auto;
  -webkit-overflow-scrolling: touch;
}

.no-scroll {
  overflow-y: hidden;
}
