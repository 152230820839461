// Mixins

@mixin transition-all() {
    -webkit-transition: all 0.5s;
    -moz-transition: all 0.5s;
    transition: all 0.5s;
}

@mixin background-cover() {
    -webkit-background-size: cover;
    -moz-background-size: cover;
    background-size: cover;
    -o-background-size: cover;
}

@mixin monospace() {
    font-family: Menlo, Monaco, Consolas, "Courier New", monospace;
}

@mixin serif() {
	font-family: 'Lora', 'Times New Roman', serif;
}

@mixin sans-serif() {
	/* Hux learn from
     *     TypeIsBeautiful,
     *     [This Post](http://zhuanlan.zhihu.com/ibuick/20186806) etc.
     */
	font-family:
        // System Font            // https://www.webkit.org/blog/3709/using-the-system-font-in-web-content/
        -apple-system,            // OSX ^10.11 & iOS ^9  San Francisco & 苹方 for Safari
        BlinkMacSystemFont,       // OSX ^10.11 & iOS ^9  San Francisco & 苹方 for Blink 

        // English First
        "Helvetica Neue",         // OSX
        "Arial",                  // Win "Helvetica"
        //" Segoe UI",            // Win ^8

        // Chinese Fallback
        "PingFang SC",            // OSX ^10.11 & iOS ^9  苹方（华康信凭黑）
        "Hiragino Sans GB",       // OSX ^10.6            冬青黑体
        "STHeiti",                // OSX <10.6  & iOS <9  华文黑体
        "Microsoft YaHei",        // Win                  微软雅黑
        "Microsoft JhengHei",     // Win                  微软正黑
        "Source Han Sans SC",     // SourceHan - begin    思源黑体
        "Noto Sans CJK SC",
        "Source Han Sans CN",
        "Noto Sans SC",
        "Source Han Sans TC",
        "Noto Sans CJK TC",       // SourceHan - end
        "WenQuanYi Micro Hei",    // Linux                文泉驿微米黑
        SimSun,                   // Win old              中易宋体
        sans-serif;               // System Fallback

	line-height: 1.7;
}
