@import "variables.scss";

// Sidebar Components

// Large Screen
@media (min-width: 1200px) {
  .post-container,
  .sidebar-container {
    padding-right: 5%;
  }
}
@media (min-width: 768px) {
  .post-container {
    padding-right: 5%;
  }
}

// Container of Sidebar, also Friends
.sidebar-container {
  color: $gray-light;
  font-size: 14px;
  h5 {
    color: $brand-gray;
    padding-bottom: 1em;
    a {
      color: $brand-gray !important;
      text-decoration: none;
    }
  }
  a {
    color: $gray-light !important;
    &:hover,
    &:active {
      color: $brand-primary !important;
    }
  }
  .tags {
    a {
      border-color: $gray-light;
      &:hover,
      &:active {
        border-color: $brand-primary;
      }
    }
  }
  .short-about {
    img {
      width: 80%;
      display: block;
      border-radius: 5px;
      margin-bottom: 20px;
    }
    p {
      margin-top: 0px;
      margin-bottom: 20px;
    }
    .list-inline > li {
      padding-left: 0px;
      margin-bottom:5px;
    }
  }
}
