// Directory Section

.catalog-container {
  padding: 0px;
}

.side-catalog {
  &.fixed {
    position: fixed;
    top: -21px;
  }
  &.fold {
    .catalog-toggle::before {
      content: "+";
    }
    .catalog-body {
      display: none;
    }
  }
  .catalog-toggle::before {
    content: "−";
    position: relative;
    margin-right: 5px;
    bottom: 1px;
  }
  display: block;
  overflow: auto;
  height: 100%;
  padding-bottom: 40px;
  width: 195px;
  .catalog-body {
    position: relative;
    list-style: none;
    height: auto;
    overflow: hidden;
    padding-left: 0px;
    padding-right: 5px;
    text-indent: 0;
    li {
      position: relative;
      list-style: none;
      a {
        padding-left: 10px;
        max-width: 180px;
        display: inline-block;
        vertical-align: middle;
        height: 30px;
        line-height: 30px;
        overflow: hidden;
        text-decoration: none;
        white-space: nowrap;
        text-overflow: ellipsis;
      }
    }
    .h1_nav,
    .h2_nav {
      margin-left: 0;
      font-size: 13px;
      font-weight: bold;
    }
    .h3_nav {
      margin-left: 6px;
      font-size: 13px;
      font-weight: bold;
    }
    .h4_nav {
      margin-left: 12px;
      font-size: 12px;
      a {
        max-width: 170px;
      }
    }
    .h5_nav .h6_nav {
      margin-left: 18px;
      font-size: 12px;
      a {
        max-width: 170px;
      }
    }
    .active {
      a {
        color: #0085a1 !important;
      }
      border-radius: 4px;
      background-color: #f5f5f5;
    }
  }
}

@media (max-width: 1200px) {
  .side-catalog {
    display: none;
  }
}
